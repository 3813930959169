import React from "react"
import Container from "react-bootstrap/Container"
import SEORevamp from "../components/common/SEO_Revamp"
import MainLayout from "../layouts/MainLayout"
export const Head = () => {
  return (
    <SEORevamp
      title="Integrated Management System Policy | InvoZone"
      description="Discover our comprehensive Integrated Management System Policy, ensuring excellence in quality, environment, and safety practices. Explore now!"
      image="https://invozone-backend.s3.amazonaws.com/IMF_Policy_2658f002c2.webp"
    />
  )
}
const IMS_Policy = () => {
  return (
    <MainLayout bgChanged={false}>
      <section>
        <Container>
          <h1 className="text-center mb-5">
            Quality & Information Security Management System Policy
          </h1>
          <p>
            INVOZONE (PVT.) LTD helps companies to build innovative software
            with a team of world class engineers and an innovative approach to
            each project, so clients can be confident in their success with
            INVOZONE (PVT.) LTD. Our comprehensive Quality & Information
            Security Management System in compliance with ISO 9001 & 27001
            international standards ensures that your business is protected.
          </p>
          <p>
            INVOZONE (PVT.) LTD. aims to maintain and continually improve its
            Integrated Management System (ISMS & QMS) by implementing Quality &
            information security policies including access control, acceptable
            use, clear desk clear screen, anti-malware, password protection etc.
            Additionally, it will adhere to best IMS practices that are in
            complete alliance with the industry standards while providing
            software development and custom application development services.
          </p>
          <p>
            INVOZONE (PVT.) LTD. works in accordance with the National and
            International client requirements within the jurisdictions where it
            operates, as well as fulfilling its contractual obligations. This is
            to ensure the protection of all information assets from all
            threats—internal or external, deliberate or accidental, and natural
            disasters.
          </p>
          <p>
            To achieve these objectives INVOZONE (PVT.) LTD. will ensure that:
          </p>
          <ul>
            <li>
              Business and client requirements for quality, security, and
              privacy are met.
            </li>
            <li>
              At all times, the confidentiality, integrity, and availability of
              information is maintained throughout the process flow.
            </li>
            <li>
              All corporate assets (tangible/intangible) are held in a
              physically and logically secure environment.
            </li>
            <li>
              The risks to all corporate assets (tangible/intangible) are
              assessed and against those risks, appropriate contingency and
              mitigation plans are defined.
            </li>
            <li>
              Employees are provided with safe working environments in
              compliance with best industry practices.
            </li>
            <li>
              All personnel are well-trained on information security procedures.
            </li>
            <li>
              Physical, logical and remote access to all corporate assets is
              monitored and controlled.
            </li>
            <li>
              Business continuity plans are established, maintained and tested
              as needed.
            </li>
          </ul>
          <p>
            This policy was approved by the INVOZONE (PVT.) LTD. Chief Executive
            and will be reviewed at regular management review meetings by senior
            management.
          </p>
          <p>
            For any queries, drop a message at{" "}
            <a href="mailto:info@invozone.com" target="_blank" rel="noreferrer">
              info@invozone.com
            </a>
          </p>
        </Container>
      </section>
    </MainLayout>
  )
}

export default IMS_Policy
